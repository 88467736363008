@property --progress-value {
    syntax: '<integer>';
    inherits: false;
    initial-value: 0;
}

.progress-bar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 40px;
    font-weight: bold;
    color: #000;
    background: #fff;
}

.progress-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@keyframes progress-animation {
    0% {
        --progress-value: 0;
    }

    100% {
        --progress-value: 100;
    }
}

.digits {
    background:
        radial-gradient(closest-side, white 79%, transparent 80% 100%),
        conic-gradient(#206533 calc(var(--progress-value) * 1%), lightgreen 0);
    animation: progress-animation 5s linear infinite;
}

progress {
    visibility: hidden;
    width: 0;
    height: 0;
}

.symbols-select-container {
    position: relative;
    z-index: 1000;
    overflow: visible;
}
