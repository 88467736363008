.join-quiz-container {
    text-align: center;
    margin-top: 50px;
}

.join-quiz-heading {
    font-size: 24px;
    margin-bottom: 20px;
}

.links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link-item {
    margin-bottom: 10px;
}

.link-item a {
    color: #007bff;
    text-decoration: none;
    font-size: 18px;
}

.link-item a:hover {
    text-decoration: underline;
}