.google-guest-login , .google-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.google-guest-login-text {
    cursor: pointer;
    margin-top: 5px;
    background-color: #eaeaea;
    padding: 10px 35px;
    border-radius: 8px;
    color: #676767;
    font-weight: bold;
}

.google-guest-login-text span , .google-guest-login-text svg {
    padding: 0px 10px;
}

.google-guest-login-text:hover , .google-login-text:hover {
    background-color: #206533;
    color: white;
}

.google-login {
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-login-text {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
    background-color: #eaeaea;
    padding: 10px 35px;
    border-radius: 8px;
    color: #676767;
    font-weight: bold;
}

.google-login-text span {
    margin-left: 10px;
}

.google-login svg {
    width: 20px;
    height: 25px;
}

