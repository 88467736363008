.read-messages {
  background-color: white;
  padding: 25px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 8px;
}

.unread-messages {
  background-color: rgb(233 255 234); 
  padding: 25px;
  border-radius: 8px;
}

.message-container {
  margin-top: 60px ;
  cursor: pointer;
}

.message-container p {
  font-size: 20px;
  margin-bottom: 0px;
}

.mark-as-read {
  float: right;
  margin-top: 10px;
}

.message-img{
  height: 40px;
  border-radius: 50%;
  margin: 0px 10px;
}

.time {
  margin-left: 60px;
  color: #d5d4d4;
}

.not-found {
  display: flex;
  justify-content: center;
}