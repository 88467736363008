.create-quiz-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    max-width: 500px;
    margin: 0 auto;
}

.question-input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.option-container {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.radio-button {
    margin-right: 10px;
}

.option-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.add-btn,
.save-btn {
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-btn:hover,
.save-btn:hover {
    background-color: #40b646;
}

.join-btn {
    margin-top: -12px;
}

.question {
    display: flex;
    align-items: center;
}

.question h2 {
    margin: 10px 10px;
    flex-grow: 1;
}

@media (max-width: 768px) {
    .option-container {
        flex-direction: column;
    }

    .option-input {
        width: calc(100% - 20px);
        margin-top: 10px;
    }

    .question {
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 5px;
    }
}

.add-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}