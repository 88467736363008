.grid-parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-container {
    display: grid;
    grid-template-columns: 2fr 0fr 2fr 0fr 2fr 0fr 2fr 0fr 2fr 0fr 2fr 0fr 2fr;
    grid-template-rows: repeat(7, 2fr 0.3fr);
    gap: 2px;
    width: 450px;
    height: 450px;
    transform: perspective(600px) rotateX(20deg);
    border-radius: 6px;
    margin-top: -22px;
    margin-bottom: 10px;
    background-color: aliceblue;
}

.grid-cell {
    border: 1px solid #b6cdb7;
    position: relative;
    padding: 2px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
    background: transparent;
}

.grid-container .grid-cell:nth-child(2n):not(:nth-child(n+14)),
.grid-container .grid-cell:nth-child(n+14):nth-child(-n+26),
.grid-container .grid-cell:nth-child(2n):not(:nth-child(n+40)),
.grid-container .grid-cell:nth-child(n+40):nth-child(-n+52),
.grid-container .grid-cell:nth-child(2n):not(:nth-child(n+66)),
.grid-container .grid-cell:nth-child(n+66):nth-child(-n+78),
.grid-container .grid-cell:nth-child(2n):not(:nth-child(n+92)),
.grid-container .grid-cell:nth-child(n+92):nth-child(-n+104),
.grid-container .grid-cell:nth-child(2n):not(:nth-child(n+118)),
.grid-container .grid-cell:nth-child(n+118):nth-child(-n+130),
.grid-container .grid-cell:nth-child(2n):not(:nth-child(n+144)),
.grid-container .grid-cell:nth-child(n+144):nth-child(-n+156),
.grid-container .grid-cell:nth-child(2n):not(:nth-child(n+170)){
    border: none;
}

.participant {
    font-size: 24px;
    font-weight: bold;
}

.highlighted-cell {
    background-color: #b7d3eb;
}

.fan-count {
    display: inline-block;
    margin: 12px;
}

.fan-icon {
    display: block;
    width: 40px;
    height: 10px;
    background-color: #26239f;
    margin-bottom: 2px;
}

.fan-icon.non-host {
    background-color: #227025;
}

.host-wall {
    background-color: #26239f;
}

.participant-wall {
    background-color: #4caf50;
}

.next-turn-pawn-cell {
    background-color: #ffeb3b;
    animation: highlight 1s infinite alternate;
}

@keyframes highlight {
    0% {
        background-color: #ffef5f;
    }

    100% {
        background-color: transparent;
    }
}

.pawn-icon {
    width: 50px;
    height: 60px;
}

@media (max-width: 768px) {
    .grid-container {
        width: 350px;
        height: 350px;
        margin-top: -8px;
    }

    .grid-cell {
        padding: 1px;
    }

    .pawn-icon {
        width: 40px;
        height: 50px;
    }

    .participant {
        font-size: 20px;
        margin-top: 15px;
    }

   .fan-icon {
       display: block;
       width: 20px;
       height: 10px;
       margin-bottom: 2px;
   }

   .fan-count {
       margin: 10px;
   }
}

@media (max-width: 480px) {
    .grid-container {
        width: 250px;
        height: 250px;
        margin-top: -8px;
    }

    .grid-cell {
        padding: 1px;
    }

    .pawn-icon {
        width: 25px;
        height: 30px;
    }

    .participant {
        font-size: 16px;
        margin-top: 10px;
    }

    .fan-icon {
        display: block;
        width: 15px;
        height: 8px;
        margin-bottom: 2px;
    }

    .fan-count {
        margin: 6px;
    }
}

@media (max-width: 380px) and (min-width: 240px) {
    .grid-container {
        width: 170px;
        height: 200px;
        margin-top: -8px;
    }

    .grid-cell {
        padding: 0px;
    }

    .pawn-icon {
        width: 18px;
        height: 20px;
    }

    .fan-icon {
        display: block;
        width: 12px;
        height: 5px;
        margin-bottom: 2px;
    }

    .fan-count {
        margin: 4px;
    }
}
