.blog-container {
  margin-top: 40px;
}

.container .blog-content {
  background-color: azure;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.container .row .card {
  margin-top: 20px;
  width: 300px;
  margin: 10px;
  flex: auto;
  padding: 0;
}

.card-img-top {
  width: 100%;
  height: 250px;
  object-fit: cover;
  display: block;
}

.link-style {
  color: #7a28ff;
  font-size: 14px;
}

.container .blog-content h1 {
  font-weight: 600;
  color: #333;
  text-align: center;
}

.container .blog-content h4 {
  font-size: 22px;
  font-weight: 700;
  margin-top: 20px;
}

.container .blog-content p {
  color: #555;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.container .blog-content b {
  color: green;
}

.container .blog-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.container .blog-content li {
  margin-bottom: 10px;
  font-size: 18px;
}

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.next-previous {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.next-previous .previous,
.next-previous .next {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  cursor: pointer;
  flex: 1;
}

.next-previous .previous .icon,
.next-previous .next .icon {
  color: green;
  font-size: xx-large;
}

.next-previous .previous .previous-title,
.next-previous .next .next-title {
  display: inline-block;
  max-width: 150px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.next-title{
  margin-right: 22px;
  font-size: small;
  margin-top: -12px;
}

.previous-title {
  margin: -15px 0px 6px 35px;
  font-size: small;
}

.previous {
  align-items: flex-start;
}

.next {
  align-items: flex-end;
}

.card-img {
  width: 100%;
  height: 250px;
}

.next-previous .previous .previous-text,
.next-previous .next .next-text {
  margin: 5px 0;
  font-size: small;
}

.image-container img {
  width: 350px;
  height: 250px;
}

@media (max-width: 400px) {

  .next-previous .previous .previous-title,
  .next-previous .next .next-title {
    max-width: 80px;
  }

  .next-previous .previous-title,
  .next-previous .next-title {
    font-size: 10px;
  }

  .next-previous .previous .previous-text,
  .next-previous .next .next-text {
    font-size: 12px;
  }
}

@media (max-width: 768px) {    
  .image-container {
    text-align: center;
  }

  .image-container img {
    max-width: 60%;
    height: 80%;
    display: inline-block;
  }
}

