.feedback-form-container {
  width: 60%;
  margin: 45px auto 0;
}

.feedback-inputs {
  margin-bottom: 20px;
}

.feedback-label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #37423b;
}

.feedback-inputs input,
.feedback-inputs textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s ease; 
}

.feedback-inputs input:focus,
.feedback-inputs textarea:focus {
  outline: none;
  border-color: #206533; 
}

.feedback-inputs input[type="submit"] {
  background-color: #206533;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feedback-inputs input[type="submit"]:hover {
  background-color: #3ac162;
}

@media screen and (max-width: 768px) {
  .feedback-form-container {
    width: 80%; 
    margin-top: 30px; 
  }

  .col-md-6 {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 20px; 
  }
}
