.sign-up-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 10px;
    background-color: #fff;
    padding-bottom: 30px;
}

.text {
    color: #206533;
    font-size: 48px;
    font-weight: 700;
}

.inputs {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input {
    display: flex;
    align-items: center;
    margin: auto;
    width: 480px;
    height: 65px;
    background-color: #eaeaea;
    border-radius: 6px;
}

.input img{
    margin: 0px 30px;
}

.input input{
    height: 50px;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 19px;
}

.submit-container {
    display: flex;
    gap: 30px;
    margin: 60px auto;
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 50px;
    color: #fff;
    background-color: #206533;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
    border: none;
}

.submit:hover {
    background-color: #1d6436;
    color: white;
}

.gray {
    background-color: #EAEAEA;
    color: #676767;
}

.sign-in-error {
    display: flex;
    justify-content: center;
    color: red;
}

@media (max-width: 768px) {
    .submit-container {
        flex-direction: column;
        margin: 30px auto;
    }

    .submit {
        width: 150%;
        margin-bottom: 5px;
    }
}

@media (max-width: 768px) {
    .inputs {
        margin-top: 25px;
    }

    .input {
        width: 90%;
        margin: auto;
    }

    .input img {
        margin: 0px 10px;
    }

    .input input {
        width: 100%;
        font-size: 16px;
    }
}

.forgot-password {
    display: flex;
    justify-content: end;
    margin-right: 54%;
    cursor: pointer;
}

.forgot-password a {
    color: black;
}

.forgot-password:hover {
    color: #206533;
}

.fogot-password-submit-button {
    display: flex;
    justify-content: center;
}

.verify-link {
    background-color: #206533;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
}

.verify-link:hover {
    background-color: green;
}

.sign-up-link {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
}