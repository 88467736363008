.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.profile-box {
    width: 35%;
    height: 40%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.profile-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile-details input {
    width: calc(100% - 2px);
    padding: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

.save-profile {
    width: fit-content;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #206533;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-profile a {
    color: white;
}

.save-profile:hover {
    background-color: #04581d;
    color: white;
}

@media (max-width: 768px) {
    .profile-box {
        width: 90%;
        height: auto;
        padding: 10px;
    }

    .profile-img {
        width: 120px;
        height: 120px;
        margin-bottom: 15px;
    }

    .profile-details input {
        padding: 15px;
        font-size: 14px;
    }
}

.rank {
    text-align: center;
    margin-top: 20px;
}

.user_rank {
    display: inline-block;
    color: #2d5739;
    font-size: 35px;
    font-family: 'Courier New', Courier, monospace;
}

@media (max-width: 768px) {
    .stars {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .star {
        margin: 5px 0;
        font-size: 20px;
    }
}