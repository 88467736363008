.wheel-container {
    position: relative;
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: calc(50% - 250px);
    margin-top: 20px;
    margin-bottom: 20px;
}

.wheel-container::before{
    content: '';
    position: absolute;
    margin-bottom: 345px;
    width: 10px;
    height: 50px;
    background: #f43648;
    z-index: 1;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    margin-left: 68px;
    transform: rotate(10deg);
}

.wheel {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 3px solid #45a049;
    box-shadow: 0 0 10px #45a049;
    border-radius: 50%;
    transition: transform 2s ease-in-out;
    overflow: hidden;
}

.wheel span {
  position: absolute;
  left: calc(50% - 1px);
  width: 2px;
  height: 100%;
  background: #45a049;
  transform: rotate(calc(45deg * var(--i)));
  filter: drop-shadow(0 0 5px #45a049);
}

.spin-button {
    position: absolute;
    inset: 140px;
    background: #45a049;
    color: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    filter: drop-shadow(0 0 5px #45a049);
    user-select: none;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 600;
    letter-spacing: 0.1em;
}

.number {
    position: absolute;
    inset: 0;
    transform: rotate(22.5deg);
}

.number b {
    position: absolute;
    inset: 10px;
    transform: rotate(calc(45deg * var(--i)));
    text-align: center;
    font-size: 2em;
    color: #0b423a;
    font-weight: 500;
    filter: drop-shadow(0 0 5px #45a049);
}

.inner {
    position: absolute;
    width: 350px;
    height: 350px;
    border: 3px solid #45a049;
    transition: transform 1s ease-in-out;
}
.multiplication-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: xxx-large;
}

.answer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 15px;
}

.answer-input {
    border-radius: 6px;
    border: 1px solid #e0e5e2;
    padding: 10px;
    width: 80%;
    max-width: 300px;
    font-size: 24px;
}

.answer-input:focus{
    outline: 2px solid #5bd78c;
    cursor: pointer;
}

.correct-answer-message{
   display: flex;
   justify-content: center;
}

@media (max-width: 768px) {
    .wheel-container {
        width: 90vw;
        height: 90vw;
        margin: 20px auto;
    }

    .wheel-container::before {
        margin-bottom: 55%;
        width: 2vw;
        height: 10vw;
        margin-left: calc(45% - 5vw);
        transform: rotate(30deg);
    }

    .spin-button {
        font-size: 1em;
        padding: 1em;
        inset: calc(80% - 42vw);
    }

    .number b {
        font-size: 1.5em;
    }

    .inner {
        width: 60vw;
        height: 60vw;
    }
}

