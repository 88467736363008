.chat-box {
  position: fixed;
  bottom: 40px;
  right: 16px;
  max-height: 70vh;
  width: 100%;
  max-width: 320px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(17, 17, 26, 0.1),
    0px 8px 32px rgba(17, 17, 26, 0.05);
  z-index: 999;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 400px;
}

@media (max-width: 768px) {
  .chat-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    max-height: 100%;
    max-width: 100%;
    margin: 0;
    min-height: 200px;
  }
}

.chat-header {
  background-color: #206533;
  display: flex;
  justify-content: space-between;
  padding: 16px 14px;
  border-radius: 10px 10px 0 0;
  color: #fff;
}

.chat-messages {
  flex: 1;
  overflow: auto;
  padding: 10px;
  min-height: 0;
  overflow-x: hidden;
}

.chat-message {
  padding: 10px;
}

.message-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 0 0 10px 10px;
}

.message-input-field {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
}

.message {
  margin: 15px 0;
  font-weight: bold;
}

.message-sender {
  font-weight: bold;
}

.send-button {
  background: #206533;
  color: white;
  border: none;
  border-radius: 0 10px 10px 0;
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.3s;
}

.chat-header h4 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.close-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: white;
  outline: none;
}

input:focus {
  outline: none;
}

.chat-box.hidden {
  display: none;
}

.message-sender {
  font-weight: bold;
}

.message-text {
  font-weight: normal;
  margin: 10px;
}

.room-chat {
  position: fixed;
  bottom: 55px;
  right: 20px;
  z-index: 10;
  font-size: 30px;
}

.chat-options {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px 0px 25px;
}

.chat-options span {
  padding: 10px;
  font-weight: 700;
}

.chat-options span:hover {
  color: #206533;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  font-size: large;
  font-weight: bold;
}

.tab.active {
  border-bottom: 2px solid black;
  font-size: small;
}

.unread-count {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 50%;
  background-color: #206533;
  color: white;
  font-weight: bold;
}

.global-chat {
  position: fixed;
  background-color: #206533;
  display: block;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  border-right: none;
  color: #fff;
  font-weight: 700;
  font-size: 30px;
  bottom: 80px;
  right: 20px;
  border: 0;
  z-index: 9999;
  width: 50px;
  height: 50px;
  line-height: 50px;
  transition: transform 0.5s ease;
}

.global-chat::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  opacity: 0;
  animation: pluse-border 1500ms ease-out infinite;
}

.global-chat.opened::before {
  opacity: 0;
}

.global-chat.closed::before {
  opacity: 1;
}

.global-chat:focus {
  border: none;
  outline: none;
}

.global-chat.hidden {
  display: none;
}

.global-chat:hover {
  background-color: #206533;
  color: white;
  transform: scale(1.5);
}

@keyframes pluse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.signin-input {
    display: flex;
    padding: 10px;
    background: #f5f5f5;
    border-radius: 0 0 10px 10px;
    justify-content: center;
    cursor: pointer;
}

.signin-input:hover {
  color: #206533;
}