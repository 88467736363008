.course-item {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.course-item img {
    height: 200px;
    object-fit: cover;
    width: 100%;
}

.course-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
}

.course-item h4,
.course-item h3 {
    margin: 0;
}

.course-item p {
    margin: 10px 0;
}

.slick-dots li button:before{
    font-size: 15px;
    color: #206533;
}

