.quiz-container {
    max-width: 500px;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.question-text {
    margin-bottom: 1.5rem;
    color: #333333;
}

.options-form {
    display: flex;
    flex-direction: column;
}

.option-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.radio-input {
    margin-right: 0.5rem;
}

.option-label {
    font-size: 1rem;
    color: #555555;
    cursor: pointer;
}

.check-answer-btn {
    padding: 0.8rem 2rem;
    background-color: #4caf50;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: center;
}

.check-answer-btn:hover {
    background-color: #45a049;
}

.quiz-answer-input {
    width: 80%;
    margin-left: 38px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid #f1ebeb;
}

.quiz-answer-textarea{
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid #f1ebeb;
}

.quiz-answer-textarea:focus {
    outline: none;
   
}
